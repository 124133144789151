.icon-arrow-left {
  width: 1.88em;
  height: 1em;
  fill: initial;
}
.icon-arrow-right {
  width: 1.88em;
  height: 1em;
  fill: initial;
}
.icon-cart {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-catalog-toggle {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-column {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-docs {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: initial;
}
.icon-favorite {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-filter {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-gear {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-info {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-menu {
  width: 1.26em;
  height: 1em;
  fill: initial;
}
.icon-notify {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-phone {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-pin {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-row {
  width: 1.05em;
  height: 1em;
  fill: initial;
}
.icon-search {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-spoiler-arrow {
  width: 2em;
  height: 1em;
  fill: initial;
}
.icon-star {
  width: 1.09em;
  height: 1em;
  fill: initial;
}
.icon-telegram {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-user {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-whatsapp {
  width: 1em;
  height: 1em;
  fill: initial;
}
