.catalog-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @include text16;
    padding: .25rem 0 2.75rem;
    @include r($sm) {
        flex-direction: column;
        align-items: flex-start;
    }
    &__sort {
        display: flex;
        align-items: center;
        color: $darkBlue;
        font-size: 14px;
        &-link {
            margin-left: 0.3rem;
            color: $orange;
            transition: .3s ease;
            &:hover {
                color: $coral;
            }
        }

        .dropdown-item {
            color: $darkBlue;
        }
        .dropdown-item.active, .dropdown-item:active {
            background: $darkBlue;
            color: $white;
        }
    }

    &__type {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.views {
    &-list {
        display: flex;
        gap: 12px;
        @include r($md) {
            margin-top: 20px;
            gap: 30px;
        }

        svg {
            fill: $darkBlue;
            width: 22px;
            height: 22px;
        }
        &__item {
            opacity: 0.5;
            &.active {
                opacity: 1;
            }
        }
    }
}

.catalog-filter__toggle {
    display: none;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    @include r($md) {
        display: inline-flex;
        font-size: 14px;
        svg {
            width: 17px;
            height: 17px;
        }
    }
    @include r($sm) {
        margin-bottom: 20px;
    }
}
