.box-slider {
    &__navigation {
        display: flex;
        gap: 20px;

        &-arrow {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            cursor: pointer;
            transition: .3s ease;
            @include r($md) {
                width: 33px;
                height: 33px;
                border-radius: 4px;
            }

            svg {
                width: 60%;
                height: auto;
            }

            &.swiper-button-disabled {
                border: 1px solid #D7CEC4;
                cursor: default;

                svg {
                    fill: $darkBlue;
                }
            }

            &:not(.swiper-button-disabled) {
                background: $deepBlue;

                svg {
                    fill: $white;
                }

                &:hover {
                    background: $lightBlue;
                }
            }
        }
    }

    .swiper-slide {
        height: auto;
    }
}
