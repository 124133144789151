.manufacturer-list {
    //display: grid;
    //grid-template-columns: repeat(6, 1fr);
    //gap: 20px;
    //@include r($md) {
    //    grid-template-columns: repeat(4, 1fr);
    //}
    //@include r($sm) {
    //    grid-template-columns: repeat(2,1fr);
    //}

    .swiper {
        padding: 30px 20px;
        margin: -30px -20px;
    }

    .swiper-slide-prev,
    .swiper-slide-next + .swiper-slide + .swiper-slide + .swiper-slide + .swiper-slide + .swiper-slide {
        .manufacturer-list__item {
            box-shadow: none;
        }
    }
    @include r($xl){
        .swiper-slide-next + .swiper-slide + .swiper-slide + .swiper-slide {
            .manufacturer-list__item {
                box-shadow: none;
            }
        }
    }

    &__item {
        position: relative;
        height: 100%;
        padding: 2rem 1rem;
        display: flex;
        align-items: center;
        background: $white;
        box-shadow: 0 11px 24px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        transition: .3s ease;

        img {
            width: 100%;
            max-height: 6.25rem;
            object-fit: contain;
            transition: transform .3s ease;
        }

        &:hover {
            box-shadow: 0 11px 24px rgba(223, 100, 64, 0.26);
        }
    }
}
