.sidebar-filter {
    width: calc(100% - 20px);
    margin: 1.4rem 0;
    padding: 22px 28px;
    background: rgba(232, 222, 204, 0.4);
    border-radius: 16px;

    &__group {
        margin-bottom: 30px;
    }

    &__label {
        margin-bottom: 21px;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.3;
        @include r($md) {
            font-size: 15px;
        }
    }

    &__body {
        .form-check {
            margin-bottom: 13px;

            &-input {
                width: 21px;
                height: 21px;

                &:checked[type=checkbox] {
                    &::after {
                        width: 7px;
                        height: 12px;
                    }
                }
            }
        }
    }
}
