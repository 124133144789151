.sidebar-nav {
    width: 100%;
    margin: 0.25rem 0 1.4rem;
    padding-right: 20px;
    border-right: 2px solid $sand;
    @include r($md) {
        order: 5;
    }

    &__list {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        .sidebar-nav__list {
            padding-left: 2rem;
        }
    }

    &__heading {
        display: none;
        @include r($md) {
            display: block;
            margin-bottom: 1em;
            color: $darkBlue;
            font-size: 20px;
            line-height: 23px;
            font-weight: 600;
        }
    }

    &__item {
        &_multiline {
            & > .sidebar-nav__link {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;

            }
            & > .sidebar-nav__list {
                .sidebar-nav__link {
                    &:not(:hover) {
                        color: $darkBlue;
                    }
                }
            }
        }
        &_active {
            color: $orange;
        }
    }

    &__link {
        position: relative;
        margin-bottom: 1.6rem;
        padding-right: 2.5rem;
        display: block;
        color: $darkBlue;
        font-size: 16px;
        line-height: 1.3;
        transition: .3s ease;
        @include r($md) {
            font-size: 14px;
        }
        &:hover {
            color: $deepBlue;
        }

        .sidebar-nav__toggle svg {
            fill: $darkBlue;
        }
        &_active {
            color: $orange!important;
            .sidebar-nav__toggle svg {
                fill: $orange;
            }
        }
    }

    &__toggle {
        padding: 0.875rem;
        position: absolute;
        right: 0;
        top: 50%;
        min-width: 0.875rem;
        width: 0.875rem;
        height: 0.4375rem;
        min-height: 0.4375rem;
        transition: transform .4s ease;
        transform: translateY(-50%) rotate(180deg);
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            min-width: 0.875rem;
            min-height: 0.4375rem;
            display: block;
            fill: $darkBlue;
        }
        &.collapsed {
            transform: translateY(-50%) rotate(0deg);
            &:hover {
                transform: translateY(-50%) rotate(0deg);
            }
        }
        &:hover {
            transform: translateY(-50%)  rotate(180deg);
            svg {
                fill: $deepBlue;
            }
        }
    }
}
