.header {
    //top and elements
    @include r($md) {
        display: none;
    }
    @include text14;
    &-top {
        padding: 20px 0;
        display: grid;
        grid-template-columns: auto auto;
        gap: 1rem;
        align-items: center;
        @include text14;
    }

    &-sticky {
        position: sticky;
        top: 0;
        background: #324553;
        z-index: 10;
        @include r($md) {
            display: none;
        }
    }

    &-logo {
        display: flex;
        align-items: center;
        gap: 20px;
        color: $darkBlue;
        @include text14;
        font-weight: 300;

        &__link {

        }

        &__slogan {

        }

        img {
            width: 100%;
            height: 100%;
            max-width: 9.125rem;
            max-height: 3.75rem;
            object-fit: contain;
        }
    }

    &-navbar {
        display: flex;
        justify-content: space-between;
        gap: 3rem;
        @include r($xl) {
            gap: 2rem;
        }
        &__link {
        }
    }

    &-brand {
        height: 100%;
        padding: 16px;
        text-align: center;
        border-left: 1px solid $white;

        img {
            max-width: 125px;
            max-height: 38px;
            object-fit: contain;
        }
    }

    &-city {
        &__toggle {
            background: none;
            color: $darkBlue;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.2;
            transition: .3s ease;

            &:hover {
                color: $orange;
            }
        }
        .dropdown-menu {
            top: 1rem !important;
            left: -1.75rem !important;
        }

        .dropdown-item {
            color: $darkBlue;
        }

        .dropdown-item.active,
        .dropdown-item:active {
            color: $white;
            background: $orange;
        }
    }

    &-phone {
        font-weight: bold;
    }

    &-cart {
        display: flex;
        justify-content: flex-end;
    }

    // middle and elements
    &-middle {
        min-height: 72px;
        padding: 0;
        display: grid;
        grid-template-columns: 1.2fr 0.9fr 3fr .9fr;
        align-items: center;
        gap: 10px;

        a {
            color: $white;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.2;
            transition: .3s ease;
            &:hover {
                color: $orange;
            }
        }
    }

    &-catalog {
        &__toggle {
            width: 100%;
            display: flex;
            align-items: center;
            svg {
                width: 1.875rem;
                height: 1.875rem;
                margin-right: 1rem;
                fill: $orange;
            }
        }
    }

    &-search {
        position: relative;
        height: 100%;

        .form-search {
            position: relative;
            height: 100%;
        }

        &__input {
            height: 100%;
            padding: 25px 42px;
            background: $sand!important;
            color: rgba(50, 69, 83, 0.33);
            font-size: 18px;
            font-weight: 600;
            border: none!important;
            border-radius: 0;
            box-shadow: none !important;
        }

        &__icon {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            padding: 21px;
            background: $sand;
            z-index: 2;

            svg {
                width: 29px;
                height: 29px;
            }
        }
    }

    &-controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;

        & > * {
            color: $darkBlue;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.2;
            transition: .3s ease;
            &:hover {
                color: $orange;
            }
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            line-height: 0.875rem;
            font-weight: bold;
            svg {
                width: 1.875rem;
                height: 1.875rem;
                margin-left: 1rem;
                transition: .3s ease;
                @include r($sm) {
                    margin-left: 0;
                }
            }
            &:hover {
                .header-controls__item_counter {
                    background: $orange;
                }
                svg {
                    fill: $orange;
                }
            }
            &_favorite {

            }

            &_menu {
                color: $orange;

                svg {
                    stroke: $orange;
                }
            }

            &_cabinet {
                svg {
                    width: 1.75rem;
                    height: 1.75rem;
                }
            }

            &_cart {
                svg {
                    margin-left: 0;
                    margin-right: 1rem;
                    fill: $white;
                    transition: .3s ease;
                }

                &:hover {
                    svg {
                        fill: $orange;
                    }
                }
            }

            &_counter {
                display: flex;
                align-items: center;
                justify-content: center;
                @include text14;
                width: 27px;
                height: 27px;
                background: $white;
                border-radius: 50%;
                margin-left: 1rem;
                overflow: hidden;
                color: $darkBlue;
                transition: .3s ease;
            }
        }


    }

    // bottom and elements
    &-bottom {
        padding: 0.2rem 0 1rem;
        display: grid;
        grid-template-columns: 16.2% 1fr;
        gap: 1rem;
        align-items: center;
    }

    &_short {
        .header-top {
            grid-template-columns: 56% 1fr;
        }

    }

}

.offcanvas {
    &-catalog {
        width: 100%;
        max-width: 85.625rem;
        .offcanvas-body, .offcanvas-header {
            padding: 1.375rem 3.0625rem;
        }
        .offcanvas-header {
            display: grid;
            grid-template-columns: 1.6fr 3fr;
            &__controls {
                display: flex;
                justify-content: space-between;
                .header-search__input {
                    border-radius: 0;
                    border-left: 0;
                    border-top: 0;
                    border-right: 0;
                    padding-left: 0;
                    max-width: 41.1875rem;
                }
            }
        }
        .btn-close {
            position: absolute;
            right: 1.5rem;
            top: 1.5rem;
        }
        &__title {
            font-weight: 700;
            @include text25;
            color: #00274B;
            margin-bottom: 2rem;
        }
        &__list {
            display: grid;
            grid-template-columns: 1.6fr 3fr;
        }
        &__nav {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 0.9375rem;
            button {
                display: flex;
                align-items: center;
                background: transparent;
                width: 100%;
                font-weight: 400;
                @include text14;
                color: #454545;
                position: relative;
                &::after {
                    content: '';
                    display: inline-block;
                    width: 1.625rem;
                    height: 0.4375rem;
                    margin-left: 1.5625rem;
                    opacity: 0;
                    transform: translateX(-0.9375rem);
                    transition: opacity .2s ease, transform .2s ease;
                    background: url('./../../../img/icons/arrow.svg');
                }
                &:hover {
                    &::after {
                        opacity: 1;
                        transform: none;
                    }
                }
                &.active {
                    font-weight: 600;
                }
                img {
                    margin-right: 1.875rem;
                    width: 1.875rem;
                    height: 1.875rem;
                    object-fit: contain;
                }
            }
            &-list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                @include text14;
            }
            &-link {
                margin-bottom: 1.5rem;
            }
        }
    }
}



.easy-autocomplete {
	max-width: 100%;
}
.search-img {
    width: 4.1875rem;
    height: 4.1875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    position: relative;
    z-index: 1;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(#005EB5, 0.04);
        border-radius: 13px;
        z-index: 1;
    }
	img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
        position: relative;
    }
}
.search-name {
    flex-grow: 1;
	padding: 0 1.5625rem;
    font-weight: 500;
    @include text14;
    color: #00274B;

}
.search-cost {
    min-width: 5.625rem;
    font-weight: 500;
    @include text14;
    color: #00274B;
}

.eac-item a {
    display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: space-between;
	span {
        display: block;
    }
}
.easy-autocomplete-container {
    background: #FFFFFF;
    overflow: hidden;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}
.search-head {
    position: relative;
    width: 100% !important;
    height: 100%;
    max-width: 796px;
    @include r($md) {
        max-width: 100% !important;
    }
}
.easy-autocomplete-container {
    margin-top: 0.375rem;
	ul {
        border: 0 !important;
        margin-top: 0.9375rem !important;
        margin-bottom: 0.9375rem !important;
		li {
			padding: 0.3125rem 1.25rem;
            border: 0 !important;
            &:hover {
                .search-name {
                    color: $primary;
                }
                background: $white !important;
            }
        }
    }
}

.mm-spn.mm-spn--navbar:after {
    content: none;
}

.mm-spn.mm-spn--navbar ul {
    top: calc(var(--mm-spn-item-height) * 1.5 + 1px);
}

.mobile-header {
    display: none;
    position: sticky;
    top: 0;
    background: #fff;
    padding: 1.5rem 0 0.5rem;
    z-index: 10;
    @include r($md) {
        display: block;
    }
    &__grid {
        display: grid;
        grid-template-columns: 2rem 1fr 1fr;
        align-items: center;
        gap: 1.625rem;
    }
    &__toggle svg {
        width: 1.625rem;
        height: 1.625rem;
    }
    .header-logo img {
        max-width: 5.625rem;
    }

    &__search {
        padding: 2rem 0 1rem;
        @include r($sm) {
            padding: 11px 0 26px;
        }
    }

    .header-controls {
        @include r($sm) {
            font-size: 0;
            gap: 1.75rem;
        }
    }

    &__toggle {
        svg {
            stroke: $darkBlue;
        }
    }

    .header {
        &-logo {
            &__slogan {
                display: none;
            }

            img {
                max-width: 84px;
                max-height: unset;
            }
        }

        &-controls {
            justify-content: flex-end;
            gap: 21px;

            &__item {
                &_phone {
                    svg {
                        fill: $darkBlue;
                    }
                }

                &_cart {
                    svg {
                        margin-right: 0;
                        fill: $darkBlue;
                    }
                }

                &_counter {
                    width: 22px;
                    height: 22px;
                    background: $darkBlue;
                    color: $white;
                    font-size: 10px;
                }
            }
        }

        &-search {
            &__input {
                padding: 15px 17px;
                color: $darkBlue;
                font-weight: 400;
                border-radius: 7px;
            }

            &__icon {
                display: none;
            }
        }
    }
}

.mmenu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 622px;
    height: 100vh;
    padding: 95px;
    transform: translateX(100%);
    background: $orange;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    transition: .3s ease;
    z-index: 12;
    @include r($md) {
        padding: 55px 25px;
    }

    &__opened {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
    }

    &__mask {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: $darkBlue;
        opacity: 0;
        visibility: hidden;
        transition: .3s ease;
        z-index: 11;

        &.opened {
            opacity: .8;
            visibility: visible;
        }
    }

    &__close {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
        @include r($md) {
            top: 25px;
            right: 25px;
        }

        svg {
            width: 33px;
            height: 33px;
            fill: $white;
            @include r($md) {
                width: 21px;
                height: 21px;
            }
        }
    }

    &__list {
        display: flex;
        flex-flow: column;
        gap: 1em;
        font-size: 30px;
        font-weight: 700;
        line-height: 1.5;
        @include r($md) {
            font-size: 25px;
        }
    }

    &__item {
        width: fit-content;
        color: $white;
        transition: .3s ease;
        &:hover {
            color: $coral;
        }
    }
}
