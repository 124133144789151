.advantages-list {
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @include r($md) {
        grid-template-columns: repeat(2, 1fr);
    }

    &__container {
        background: $sand;
    }

    &__item {
        padding: 20px 15px;
        display: flex;
        flex-flow: column;
        align-items: center;
        text-align: center;
        border: 1px solid $white;
        &:nth-child(1) {
            border-top: unset;
            border-left: unset;
        }
        &:nth-child(2) {
            border-top: unset;
        }
        &:nth-child(3) {
            border-top: unset;
            border-right: unset;
        }
        &:nth-child(4) {
            border-bottom: unset;
            border-left: unset;
        }
        &:nth-child(5) {
            border-bottom: unset;
        }
        &:nth-child(6) {
            border-bottom: unset;
            border-right: unset;
        }
        @include r($md) {
            border: unset;
        }

        .item {
            &__number {
                width: 47px;
                height: 47px;
                margin-bottom: 8px;
                padding: 11px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $deepBlue;
                color: $white;
                font-size: 22px;
                font-weight: 500;
                line-height: 1;
                border-radius: 50%;
                @include r($md) {
                    width: 33px;
                    height: 33px;
                    font-size: 14px;
                }
            }

            &__heading {
                max-width: 260px;
                color: $darkBlue;
                font-size: 22px;
                font-weight: 500;
                line-height: 1.35;
                @include r($md) {
                    font-size: 12px;
                }
            }
        }
    }
}
