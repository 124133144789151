.order {
    @include r($md) {
        margin-bottom: -4.7rem;
        padding-bottom: 0;
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr 27.5rem;
        gap: 2.5rem;
        @include r($md) {
            grid-template-columns: 1fr;
        }
    }

    &-table {
        background: #F6F2EB;
        padding: 29px 26px;
        display: flex;
        flex-flow: column;
        gap: 20px;
        border-radius: 16px;
        @include r($md) {
            margin: 0 -15px;
            padding: 25px;
            gap: 25px;
            border-radius: 0;
        }

        &__button {
            width: 100%;
            max-width: 381px;
            padding: 10px;
            display: block;
            background: $orange;
            color: $white;
            font-size: 16px;
            line-height: 1.95;
            border-radius: 41px;
            text-align: center;
            transition: .3s ease;
            &:hover {
                background: $coral;
                color: $white;
            }
            @include r($md) {
                margin-top: 25px;
                font-size: 10px;
                order: 998;
            }
        }

        &__desc {
            padding: 0 14px;
            font-size: 14px;
            line-height: 1.6;
            color: #6F6F6F;
            @include r($md) {
                font-size: 12px;
                order: 999;
            }
        }

        .hr {
            margin: 0;
            height: 3px;
            background: $sand;
            border-radius: 10px;
            opacity: 1;
        }

        &__cart {
            padding: 0 14px;

            &-title {
                margin-bottom: 31px;
                font-size: 22px;
                font-weight: 700;
                line-height: 1.2;
            }

            &-list {
                display: flex;
                flex-flow: column;
                gap: 20px;
                @include r($md) {
                    gap: 25px;
                }
            }

            &-line {
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                line-height: 1;

                span:nth-child(2) {
                    font-size: 22px;
                    font-weight: 700;
                    @include r($md) {
                        font-size: 20px;
                    }
                }
            }
        }

        &__title {
            font-weight: 600;
            @include text22;
        }
    }

    &-form {
        &__group {
            margin-bottom: 50px;
            @include r($md) {
                margin-bottom: 28px;
            }

            &-header {
                margin-bottom: 33px;
                font-size: 25px;
                font-weight: 700;
                line-height: 1.15;
                @include r($md) {
                    font-size: 18px;
                }
            }

            input:not([type="radio"]),
            textarea {
                width: 100%;
                padding: 18px 24px;
                font-size: 18px;
                line-height: 1.55;
                border: 1px solid #E8DECC;
                border-radius: 12px;
                @include r($md) {
                    padding: 9px 24px;
                    font-size: 14px;
                    line-height: 2;
                }
            }

            .order-table__button {
                max-width: 381px;
            }


            .form-check {
                &:not(:last-child) {
                    margin-bottom: 25px;
                }
            }

            .has-danger {
                input,
                textarea {
                    border-color: $orange;
                }
            }

            input, textarea, select, .choices {
                max-width: 36.25rem;

                &:focus {
                    border-color: #E8DECC;
                    box-shadow: none;
                }
            }

            textarea {
                resize: none;
            }

            &-body {
                max-width: 42.5rem;

                .form-label {
                    margin-bottom: 11px;
                    padding-left: 24px;
                    font-size: 14px;
                    line-height: 1.2;
                }

                p {
                    padding-left: 24px;

                    a {
                        color: $orange!important;
                        text-decoration: underline;
                        transition: .3s ease;
                        &:hover {
                            color: $coral!important;
                        }
                    }
                }
            }

            .choices {
                margin-bottom: 11px;

                &__inner {
                    padding: 18px 24px!important;
                    font-size: 18px;
                }

                &__list {
                    &--single {
                        padding: 0!important;
                    }
                }
            }

            .choices__list--dropdown .choices__item {
                color: $darkBlue;
            }
        }
    }
}
