.hero__slider {
    --swiper-theme-color: rgba(255,255,255,0.5);
    width: 100%;
    overflow: hidden;
    position: relative;
    .swiper-slide {
        min-height: 33.125rem;
        border-radius: 22px;
        overflow: hidden;
        @include r($sm) {
            min-height: 12.125rem;
        }
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

}

.swiper-button-prev {
    left: 1.25rem;
}
.swiper-button-next {
    right: 1.25rem;
}

:root {
    --swiper-pagination-bullet-size: 14px;
    --swiper-pagination-bullet-horizontal-gap: 29px;
    --swiper-pagination-color: #F6F1EB;
    --swiper-pagination-bullet-inactive-color: rgba(246, 241, 235, 0.3);
    @include r($md) {
        --swiper-pagination-bullet-size: 4px;
        --swiper-pagination-bullet-horizontal-gap: 8px;
    }
}

@include r($lg) {
    :root {
        --swiper-navigation-size: 2.5rem;
    }
}
