html,
:root {
  font-size: 12px;
  transition: font-size .15s ease;
  @include rmin(768) {
    font-size: 12px;
  }
  @include rmin(992) {
    font-size: 12px;
  }
	@include rmin(1199) {
    font-size: 14px;
  }
	@include rmin(1300) {
		font-size: 15px;
  }
// 	@include rmin(1360) {
// 		font-size: 16px;
//   }
// 	@include rmin(1700) {
// 		font-size: 21px;
//   }
// 	@include rmin(2300) {
//     font-size: 28px;
//   }
}

body {
	background: #fff;
	@include text16;
	font-family: $font-primary;
	display: flex;
	flex-direction: column;
	line-height: 140%;
	-webkit-font-smoothing: antialiased;
    color: #00003E;
	// responsive
	// +css-lock(16, 50, 600, 1000)
}

.container {
    max-width: 1500px;
}

.main {
    flex-grow: 1;
}

.box-widget {
    padding: 2.5rem 0;
    @include r($md) {
        padding: 1.75rem 0;
    }
    &:first-child {
        padding-top: 1.25rem;
    }

    .container > * {
        margin: 1rem 0;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 0.5rem;
        margin-bottom: 55px!important;
        @include r($md) {
            margin-bottom: 30px!important;
        }
    }
    &__title {
        color: $darkBlue;
        @include text45;
        font-weight: 600;
    }
    &__link {
        font-weight: 400;
        @include text20;
        display: inline-block;
        margin-bottom: 1.25rem;
        border-bottom: 1px solid $primary;
        color: $primary;
        transition: border-color .15s ease;
        &:hover {
            border-color: transparent;
        }
    }

    &__body {
    }

    &__footer {

    }

}

.quantity-counter {
    display: flex;
    justify-content: center;
    @include r($md) {
        justify-content: flex-start;
    }

    &__controls {
        width: 90px;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 2px solid $sand;
        border-radius: 33px;
        @include r($md) {
            width: 86px;
            height: 40px;
        }
    }

    &__button {
        flex: 0 0 auto;
        padding: 7px;
        color: $sand;
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        cursor: pointer;
        @include r($md) {
            font-size: 14px;
        }
    }

    &__input {
        flex: 1;

        input {
            width: 100%;
            padding: 0;
            color: $darkBlue;
            font-size: 16px;
            font-weight: 700;
            line-height: 1;
            text-align: center;
            user-select: none;
            @include r($md) {
                font-size: 12px;
            }
        }
    }
}

.btn {
    &-primary {
        background: #1B8DF4;
        border-color: #1B8DF4;
    }
    &-dark {
        transition: background .2s ease;
        &:hover {
            background: lighten($dark, 15%);
        }
    }
}


.dropdown-menu {
    box-shadow: 0 0.25rem 2.5rem rgba(0, 0, 0, 0.15);
    overflow: hidden;
    min-width: 17.1875rem;
    li:not(:last-child) .dropdown-item {
        border-bottom: 1px solid #F0F0F0;
        &.active {
            border-bottom: 1px solid #006ba8 !important;
        }
        &:hover {
            border-bottom: 1px solid #f0f0f0;
        }
    }
}


// tabs
.tabs {
    &__list {
        display: flex;
        // flex-wrap: nowrap;
        flex-wrap: wrap;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__link {
        padding: 0.625rem 1.675rem;
        display: block;
        // @include text20;
        text-align: center;
        word-break: break-all;
        color: $primary;
        margin-bottom: 1rem;

        border-radius: 2.19rem;
        border: 1px solid $primary;
        background: #fff;

        &:not(:last-child) {
            margin-right: 1rem;
        }

        &:hover {
            color: $black;
        }

        &.active {
            background-color: $primary;
            border: none;
            color: #fff;
        }
    }
}

.modal {
    &-auth {
        .modal-body {
            padding-left: 5rem;
            padding-right: 5rem;
        }
        &__title {
            font-weight: 700;
            @include text35;
            margin-bottom: 1.25rem;
            text-align: center;
        }
        &-link {
            font-weight: 400;
            @include text14;
            color: #545454;
            margin: 0.75rem auto;
            a, button {
                font-weight: 400;
                @include text14;
                background: transparent;
                &:not(:hover) {
                    color: #545454;
                }

            }
        }
        &-forgot {
            margin: 1.5rem auto 0;
            text-align: center;
            display: block;
            font-weight: 400;
            @include text14;
            text-decoration-line: underline;
            color: #000000;

        }
    }
}

.badge {
    font-weight: 500;
    @include text12;
    padding: 0.375rem 0.625rem;
    &_active {
        background: #1ED300;
    }
}

.form-check-input[type="radio"] {
    width: 18px;
    height: 18px;
    flex: 0 0 18px;
    border-color: #00274B;

    &:checked {
        background-color: $orange;
        border-color: $orange;
    }
}
.form-check-input[type="radio"] + label {
    color: #00274B;
    font-size: 20px;
    line-height: 1.15;
    @include r($md) {
        font-size: 16px;
    }

    span {
        margin-top: 10px;
        display: block;
        color: #00274B;
        font-size: 16px;
        line-height: 1.2;
        @include r($md) {
            font-size: 14px;
        }
        &.text-primary {
            color: $orange!important;
        }
    }
}

.choices__inner {
    background: #FFFFFF;
    border: 1px solid #ced4da;
    // border: 1px solid #AAC0D7;
    @include text16;
    border-radius: 5px !important;
    padding: 0.46875rem 1.25rem 0.234375rem;
    display: flex;
    align-items: center;
}

.choices__list--dropdown {
    box-shadow: 0 4px 40px rgba(0,0,0, 0.15);
    border-radius: 5px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    border: 0 !important;
}
.choices__list--dropdown .choices__item {
    color: #006BA8;
    border-bottom: 1px solid #F0F0F0;
    padding: 0.625rem 1.5rem !important;
}

.form-select-lg + .choices__list {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    @include text16;
    padding-left: 0;
}

.container .container {
    padding-left: 0;
    padding-right: 0;
}

body:not(.ready) .mmenu__list {
    display: none !important;
}

.btn-warning {
    color: #fff;
}

.preloader {
    position: relative;
    z-index: 3000;
}

.preloader::before {
    content: '';
    background: rgba(255,255,255,0.8);
    z-index: 1;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.preloader::after {
    content: 'Идет загрузка...';
    z-index: 1;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 40px;
    font-size: 1.125rem;
    text-align: center;
    animation: loading 1s ease infinite;
}

@keyframes loading {
    0% {
        color: #000;
    }
    50% {
        color: rgb(122, 122, 122);
    }
    100% {
        color: #000;
    }
}

.form {
    display: flex;
    flex-flow: column;
    gap: 20px;
    @include r($md) {
        text-align: center;
    }

    &-title {
        font-size: 35px;
        font-weight: 700;
        line-height: 1.4;
        @include r($md) {
            font-size: 20px;
            line-height: 2;
        }
    }

    &-subtitle {
        font-size: 18px;
        line-height: 1.5;
        @include r($md) {
            font-size: 16px;
        }
    }

    &-fields {
        display: flex;
        flex-flow: row wrap;
        gap: 16px;
        @include r($md) {
            flex-flow: column;
            gap: 20px;
        }
    }

    &-field {
        width: 100%;
        max-width: 366px;

        input {
            width: 100%;
            padding: 11px 28px;
            background: $white;
            font-size: 16px;
            line-height: 1.95;
            border: 1px solid transparent;
            border-radius: 41px;
            transition: .3s ease;
            &::placeholder {
                color: #BCBCBC;
            }
            @include r($md) {
                text-align: center;
            }
        }

        &.has-danger {
            input {
                border-color: $orange;
            }
        }
    }

    &-check {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: #00003E;

        &.has-danger {
            flex-flow: row wrap;
        }

        &-input {
            position: relative;
            width: 33px;
            height: 33px;
            flex: 0 0 33px;
            background: $white;
            border: 1px solid $darkBlue;
            border-radius: 3px;

            &:focus {
                box-shadow: none;
            }

            &:checked[type=checkbox] {
                background: $white;

                &::after {
                    content: '';
                    position: absolute;
                    top: 40%;
                    left: 50%;
                    width: 10px;
                    height: 18px;
                    border-right: 2px solid $orange;
                    border-bottom: 2px solid $orange;
                    transform: translate(-50%,-50%) rotate(40deg);
                }
            }
        }

        &-label {
            max-width: 771px;
            margin-left: 21px;
        }

        .text-help {
            padding-left: 25px;
            flex: 1 0 100%;
        }
    }

    &-submit {
        width: 100%;
        max-width: 264px;
        padding: 10px;
        background: $orange;
        color: $white;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.95;
        border-radius: 41px;
        transition: .3s ease;
        &:hover {
            background: $coral;
        }
    }

    &-success__message {
        display: none;
    }
}

.text-help {
    margin-top: 3px;
    padding-left: 28px;
    color: $orange;
    font-size: 14px;
}
