.news-list {
    &__item {
        height: 100%;
        display: flex;
        flex-flow: column;
    }

    .item {
        &__image {
            margin-bottom: 19px;
            border-radius: 10px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__heading {
            width: fit-content;
            margin-bottom: 20px;
            color: $darkBlue;
            font-size: 18px;
            font-weight: 700;
            line-height: 1.3;
            transition: .3s ease;
            &:hover {
                color: $orange;
            }
            @include r($md) {
                margin-bottom: 13px;
                font-size: 16px;
            }
        }

        &__text {
            margin-bottom: 17px;
            color: $darkBlue;
            font-size: 16px;
            line-height: 1.3;
            @include r($md) {
                font-size: 14px;
            }
        }

        &__footer {
            margin-top: auto;
        }

        &__link {
            display: inline-block;
            color: $orange;
            font-size: 14px;
            line-height: 1.2;
            text-decoration: underline;
            transition: .3s ease;
            &:hover {
                color: $coral;
            }
            @include r($md) {
                font-size: 12px;
            }
        }

        &__date {
            margin-top: 21px;
            color: #DED8D0;
            font-size: 14px;
            line-height: 1.2;
            @include r($md) {
                margin-top: 16px;
                font-size: 12px;
            }
        }
    }

    &__link {
        width: fit-content;
        margin-top: 50px;
        padding: 10px 50px;
        display: inline-block;
        color: $deepBlue;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.95;
        border: 2px solid $deepBlue;
        border-radius: 41px;
        transition: .3s ease;
        &:hover {
            color: $lightBlue;
            border-color: $lightBlue;
        }
        @include r($md) {
            margin-top: 22px;
            padding: 5px 43px;
            font-size: 12px;
            line-height: 2.6;
        }
    }
}
