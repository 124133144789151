.page-title {
    margin-bottom: 10px;
    color: $darkBlue;
    font-size: 50px;
    font-weight: 700;
    line-height: 1;
    @include r($md) {
        font-size: 20px;
    }

    &_h2 {
        @include text35;
    }
}
