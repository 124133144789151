.catalog__form {
    margin-bottom: 50px;
    padding: 41px 51px;
    display: flex;
    align-items: center;
    gap: 50px;
    border: 1px solid $darkBlue;
    border-radius: 16px;
    @include r($md){
        padding: 0;
        margin-bottom: 25px;
        flex-flow: column;
        gap: 18px;
        text-align: center;
        border: unset;
    }

    &-button {
        width: 100%;
        max-width: 265px;
        margin: 0 auto;
        padding: 10px;
        background: $darkBlue;
        color: $white;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.95;
        text-align: center;
        border-radius: 41px;
        cursor: pointer;
        transition: .3s ease;
        &:hover {
            background: $deepBlue;
        }
        @include r($md) {
            max-width: unset;
            padding: 8px;
            font-size: 12px;
        }
    }

    &-text {
        color: $darkBlue;
        font-size: 18px;
        line-height: 1.5;
        @include r($md) {
            font-size: 12px;
        }
    }
}
