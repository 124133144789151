.product-item {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    height: 100%;
    text-align: center;
    @include r($sm) {
        padding: 0;
    }
    &__figure {
        display: block;
        position: relative;
        margin-bottom: 4px;
        &::after {
            content: '';
            position: relative;
            z-index: 1;
            display: block;
            width: 100%;
            background: transparent;
            padding-bottom: 100%;
            border-radius: 1.6875rem;
            pointer-events: none;
        }
    }

    &__image {
        position: absolute;
        left: 50%;
        top: 50%;
        object-fit: contain;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        @include r($md) {
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__body {
        max-width: 265px;
        margin: 0 auto;
    }

    &__name {
        margin-bottom: 22px;
        display: block;
        color: $darkBlue;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.3;
        transition: .3s ease;
        &:hover {
            color: $orange;
        }
        @include r($md) {
            margin-bottom: 8px;
            font-size: 12px;
        }
    }

    &__type {
        margin-bottom: 6px;
        color: $orange;
        font-size: 14px;
        line-height: 1.65;
        @include r($md) {
            margin-bottom: 4px;
            font-size: 10px;
        }
    }

    &__options {
        margin: 0.6rem 0;
        display: flex;
        justify-content: space-between;
        @include r($xl) {
            gap: 2rem;
        }
    }

    &__price {
        margin-bottom: 24px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 10px;
        font-size: 25px;
        font-weight: 700;
        line-height: 1.2;
        color: $darkBlue;
        @include r($md) {
            gap: 5px;
            font-size: 16px;
        }
        i {
            margin-left: 3px;
            font-style: normal;
        }

        &_old {
            font-size: 18px;
            font-weight: 500;
            text-decoration-line: line-through;
            color: #B4B4B4;
            @include r($md) {
                font-size: 12px;
            }
        }

        &_notify {
            color: $orange;
            font-size: 16px;
            font-weight: 700;
            line-height: 1.3;
        }
    }

    &__footer {
        margin-top: auto;
    }

    &__btn {
        width: 100%;
        max-width: 265px;
        margin: 0 auto;
        padding: 10px;
        display: block;
        background: $orange;
        color: $white;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.95;
        text-align: center;
        border-radius: 41px;
        cursor: pointer;
        transition: .3s ease;
        &:hover {
            background: $coral;
            color: $white;
        }

        span {
            display: none;
        }

        @include r($md) {
            padding: 8px;
            font-size: 12px;

            span {
                display: block;

                svg {
                    fill: $white;
                }
            }
        }

        &.active {
            background: $sand;
            color: $darkBlue;
            &:hover {
                background: $darkBlue;
                color: $white;
            }
        }

        &.out-of-stock {
            background: $darkBlue;
            &:hover {
                background: $deepBlue;
            }
        }

        &.notify {
            background: $white;
            color: $orange;
            border: 2px solid $orange;
            &:hover {
                color: $coral;
                border-color: $coral;
            }
            @include r($md) {
                span svg {
                    fill: $orange;
                }
            }
        }

        &.favorites {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            background: $white;
            color: $darkBlue;
            border: 2px solid $orange;

            svg {
                width: 21px;
                height: 21px;
                fill: $darkBlue;
                transition: .3s ease;
            }

            &:hover {
                color: $lightBlue;
                border-color: $coral;

                svg {
                    fill: $lightBlue;
                }
            }
        }
    }
    // view row
    &_row {
        display: grid;
        gap: 30px;
        grid-template-columns: 108px 4fr 1.5fr;
        align-items: center;
        @include r($md) {
            grid-template-columns: 71px 4fr 44px;
        }

        .product-item {
            &__figure {
                margin-bottom: 0;
            }

            &__body {
                max-width: unset;
                margin: 0;
                display: grid;
                align-items: center;
                gap: 30px;
                grid-template-columns: 3fr 1fr;
                @include r($md) {
                    gap: 11px;
                    grid-template-columns: 1fr;
                }
            }

            &__section {
                display: flex;
                flex-flow: column;
                gap: 8px;
                @include r($md) {
                    flex-flow: column-reverse;
                    gap: 5px;
                }
            }

            &__name {
                width: fit-content;
                margin-bottom: 0;
                font-size: 20px;
                text-align: left;
                @include r($md) {
                    font-size: 12px;
                }
            }

            &__type {
                margin-bottom: 0;
                text-align: left;
            }

            &__price {
                margin-bottom: 0;
                flex-flow: column;
                align-items: flex-start;
                @include r($md) {
                    flex-flow: row-reverse nowrap;
                    align-items: flex-end;
                    justify-content: flex-end;
                }
            }

            &__btn {
                font-size: 14px;
                @include r($md) {
                    width: 44px;
                    height: 44px;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 0;
                    border-radius: 8px;

                    svg {
                        font-size: 22px;
                    }
                }
            }

            &__remove {
                padding: 10px;
                cursor: pointer;
            }
        }


        &-cart {
            grid-template-columns: 108px 4fr .3fr;
            @include r($md) {
                grid-template-columns: 71px 4fr .5fr;
            }

            .product-item__body {
                grid-template-columns: 3fr 1fr 1fr;
                @include r($md) {
                    justify-content: flex-start;
                    grid-template-columns: 1fr;
                }
            }
        }
    }
}
