.footer {
    margin-top: 4.7rem;
    &-top {
        padding: 1.1875rem 0;
        background: $sand;
        color: #fff;
    }
    &-nav {
        &__list {
            margin: 2.1875rem 0;
            display: grid;
            grid-template-columns: 2fr 1fr 1fr;
            gap: 3.75rem;
            @include r($md) {
                display: flex;
                flex-flow: column;
                align-items: center;
                gap: 1.5rem;
            }
            a {
                width: fit-content;
                color: $darkBlue;
                transition: .3s ease;
                &:hover {
                    color: $orange;
                }
            }
        }
        &__group {
            @include r($md) {
                display: flex;
                flex-flow: column;
                align-items: center;
            }

            .footer-nav__group-list {
                @include r($md) {
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                }
            }

            &:first-child {
                .footer-nav__group-list {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 0 3.75rem;
                    @include r($md) {
                        display: flex;
                        flex-flow: column;
                        align-items: center;
                    }
                }
            }
            &-title {
                @include text22;
                font-weight: 700;
                margin-bottom: 1.75rem;
                display: block;
            }
            &-link {
                @include text16;
                display: block;
                margin-bottom: 1.5rem;
            }
        }
    }
    &-bottom {
        background: $darkBlue;
        padding: 1.6875rem 0;
        &__grid {
            display: grid;
            grid-template-columns: 1fr 3fr;
            @include r($sm) {
                display: flex;
                flex-flow: column;
                align-items: center;
                gap: 31px;
            }
        }
    }
    &-logo img {
        width: 100%;
        height: 100%;
        max-width: 174px;
        object-fit: contain;
        @include r($sm) {
            display: block;
        }
    }
    &-contacts {
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.875rem;
        @include text16;
        @include r($md) {
            flex-flow: column;
            align-items: center;
            gap: 31px;
        }
        a {
            color: #fff;
            transition: .3s ease;
            &:hover {
                color: $orange;
            }
            @include r($md) {
                font-size: 16px;
            }
        }
        &__address {
            max-width: 18.75rem;
            @include r($xl) {
                max-width: 15rem;
            }
            @include r($md) {
                width: 100%;
                max-width: 300px;
                order: 9999;
                text-align: center;
            }
        }
        &__phone {
            font-weight: 600;
        }
        &__email {
            font-weight: 600;
            border-bottom: 1px solid #fff;
            transition: border-color .15s ease;
            &:hover {
                border-color: transparent;
            }
        }
        &__social {
            display: flex;
            flex-wrap: wrap;
            gap: 22px;
            align-items: center;
            justify-content: flex-end;
            @include r($md) {
                gap: 70px;
            }
            &-item {
                display: inline-block;
                img {
                    max-width: 30px;
                    max-height: 30px;
                    object-fit: contain;
                }
            }
        }
    }
}
