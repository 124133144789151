$xl: 1359;
$lg: 1199;
$md: 991;
$sm: 767;
$xs: 575;

$lightBlue: #7A9AB2;
$darkBlue: #324553;
$deepBlue: #224C6B;
$orange: #DF6440;
$sand: #E8DECC;
$coral: #FFC1AF;
