@include font('Roboto', 'Roboto-Regular', normal);
@include font('Roboto', 'Roboto-Medium', 500);
@include font('Roboto', 'Roboto-Bold', 700);
@include font('Roboto', 'Roboto-Black', 900);

$font-primary: 'Roboto', sans-serif;

// only rem

@mixin text12 {
    font-size: .75rem;
    line-height: 130%;
}
@mixin text14 {
    font-size: .875rem;
    line-height: 130%;
}
@mixin text16 {
    font-size: 1rem;
    line-height: 140%;
}
@mixin text20 {
    font-size: 1.25rem;
    line-height: 130%;
}
@mixin text22 {
    font-size: 1.375rem;
    line-height: 130%;
}
@mixin text25 {
    font-size: 1.5625rem;
    line-height: 130%;
}
@mixin text35 {
    font-size: 2.1875rem;
    line-height: 133.1%;
    @include r($lg) {
        font-size: 2rem;
    }
    @include r($sm) {
        @include text25;
    }
}
@mixin text45 {
    font-size: 2.8125rem;
    line-height: 116.1%;
    @include r($sm) {
        @include text35;
    }
}
@mixin text55 {
    font-size: 3.4375rem;
    line-height: 116.1%;
    @include r($sm) {
        @include text45;
    }
}
@mixin text65 {
    font-size: 4.0625rem;
    line-height: 116.1%;
    @include r($sm) {
        @include text45;
    }
}

.content {
    padding: 2rem 0;
    color: $darkBlue;
    line-height: 1.2;
    @include r($sm) {
        @include text25;
        padding: 2rem 0 0;
    }

    h1 {
        font-size: 50px;
        font-weight: 700;
        margin-bottom: 2.75rem;
        @include r($md) {
            font-size: 31px;
        }
    }

    h2 {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 2.25rem;
        @include r($md) {
            font-size: 20px;
        }
    }

    p {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 1.7;
        &:not(:last-child) {
            margin-bottom: 1em;
        }
        @include r($md) {
            font-size: 14px;
            line-height: 1.4;
        }
    }

    ul, ol {
        margin-bottom: 1em;
        padding-left: 1em;
        font-size: 18px;
        line-height: 1.7;
        @include r($md) {
            font-size: 14px;
            line-height: 1.4;
        }

        li {
            &::marker {
                color: $orange;
                font-weight: 700;
            }
            &:not(:last-child) {
                margin-bottom: 1em;
            }
        }
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style: disc;
    }

    a {
        color: $orange;
        font-weight: 700;
        transition: .3s ease;
        &:hover {
            color: $coral;
        }
    }

    .table,
    table {
        max-width: 100%;

        &:not(:last-child) {
            margin-bottom: 40px;
        }

        thead {
            background: $sand;

            td {
                font-size: 21px;
                font-weight: 500;
                line-height: 1.3;
                @include r($md) {
                    font-size: 12px;
                }
            }
        }

        td {
            border: 2px solid $sand;
            padding: 17px 37px;
            vertical-align: middle;
            @include r($md) {
                padding: 15px 24px;
            }
        }

        &__heading {
            font-size: 21px;
            font-weight: 500;
            line-height: 1.3;
            &:not(:last-child) {
                margin-bottom: 13px;
                @include r($md) {
                    margin-bottom: 6px;
                }
            }
            @include r($md) {
                font-size: 11px;
            }
        }

        &__subheading {
            color: #8693A8;
            font-size: 18px;
            line-height: 1.2;
            @include r($md) {
                font-size: 10px;
            }
        }

        a {
            font-size: 21px;
            @include r($md) {
                font-size: 12px;
            }
        }
    }
}

.page__typical {
    padding-top: 0!important;

    .content {
        padding-top: 0!important;
    }
}
