.product-card {
    margin-bottom: 90px;
    @include r($md) {
        margin-bottom: 50px;
    }

    &__hero {
        display: grid;
        grid-template-columns: 1fr 1.3fr;
        gap: 5.4rem;
        padding-right: 0;
        margin-bottom: 70px;
        @include r($md) {
            grid-template-columns: 1fr;
        }
        @include r($sm) {
            margin-bottom: 2.5rem;
            gap: 0;
        }
        &-gallery {
            display: grid;
            grid-template-columns: 103px 1fr;
            gap: 20px;
            @include r($lg) {
                gap: 2rem;
            }
            @include r($md) {
                gap: 2rem;
                grid-template-columns: 0.25fr 1.5fr;
            }
            @include r($sm) {
                display: flex;
                flex-direction: column-reverse;
                flex-wrap: wrap;
                gap: 0;
            }
        }
    }

    .box-widget {
        padding: 0 0 36px;
        @include r($md) {
            padding: 0;
        }
    }

    &__info {

    }

    &__data {
        display: flex;
        flex-flow: column;

        &-group {
            margin-bottom: 30px;
        }

        &-desc {
            margin-bottom: 21px;
            color: $darkBlue;
            font-size: 18px;
            line-height: 1.5;
            @include r($md) {
                margin-bottom: 20px;
                font-size: 14px;
            }

            p {
                margin-bottom: 0;
                &:not(:last-child) {
                    margin-bottom: 1em;
                }
            }
        }
    }

    &__buy {
        &-cost {
            margin-bottom: 28px;
            display: flex;
            align-items: flex-end;
            gap: 20px;
            font-size: 30px;
            font-weight: 700;
            line-height: 1;
            @include r($md) {
                margin-bottom: 12px;
                font-size: 20px;
            }

            i {
                font-style: normal;
            }

            &_old {
                margin-bottom: 3px;
                color: #b4b4b4;
                font-size: 16px;
                font-weight: 500;
                text-decoration: line-through;
                @include r($md) {
                    font-size: 14px;
                }
            }

            &_notify {
                color: $orange;
                font-size: 18px;
                font-weight: 700;
                line-height: 1;
                @include r($md) {
                    font-size: 12px;
                }
            }
        }
        &-order {
            display: flex;
            gap: 20px;
            margin-bottom: 26px;
            align-items: center;
            @include r($md) {
                margin-top: 20px;
                margin-bottom: 0;
                order: 999;
            }

            .product-item__btn {
                margin: 0;
                @include r($md) {
                    max-width: 156px;

                    &.favorites {
                        font-size: 10px;

                        svg {
                            max-width: 14px;
                            max-height: 14px;
                        }
                    }
                }
            }
        }
    }

    &__variants {
        display: flex;
        align-items: center;
        gap: 20px;
        @include r($md) {
            flex-flow: row wrap;
            gap: 8px;
        }

        &-label {
            margin-bottom: 15px;
            color: $darkBlue;
            font-size: 18px;
            font-weight: 500;
            line-height: 1.5;
            @include r($md) {
                margin-bottom: 20px;
                font-size: 12px;
            }
        }

        &-item {
            min-width: 153px;
            padding: 10px 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            color: $darkBlue;
            font-size: 16px;
            font-weight: 500;
            line-height: 2;
            text-align: center;
            border: 2px solid $darkBlue;
            border-radius: 41px;
            transition: .3s ease;
            &:hover {
                color: $darkBlue;
                border-color: $lightBlue;
            }
            @include r($md) {
                min-width: 95px;
                padding: 5px 7px;
                font-size: 10px;
            }

            &.active {
                color: $darkBlue;
                border-color: $orange;
                &:hover {
                    color: $darkBlue;
                    border-color: $orange;
                }
            }

            &.inactive {
                filter: grayscale(1);
                color: $darkBlue;
                border-color: $orange;
                opacity: .8;
                &:hover {
                    color: $darkBlue;
                    border-color: $black;
                }
            }

            img {
                width: 35px;
                height: 35px;
                object-fit: contain;
                @include r($md) {
                    width: 22px;
                    height: 22px;
                }
            }
        }
    }

    &__order {
        &-product {
            margin: 2.5rem 0 0;
        }
        &-test {
            padding: 1.5rem 0;
            display: block;
        }
    }
}

.product-image {
    &__thumbs {
        display: flex;
        flex-direction: column;
        gap: 26px;
        @include r($sm) {
            flex-direction: row;
            overflow-x: auto;
            width: calc(100vw - var(--bs-gutter-x, 0.75rem) * 2);
            padding: 15px 0;

        }

        &-item {
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            cursor: pointer;
            height: 103px;
            @include r($sm) {
                width: 53px;
                min-width: 53px;
                height: 53px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: #0270B9;
                opacity: 0;
                z-index: 1;
                transition: opacity .15s ease;
            }
            &.active {
                &::before {
                    opacity: 0.5;
                }
            }

        }
    }
    &__main-image {
        width: 100%;
        border-radius: 1rem;
        overflow: hidden;
        padding: 2rem;
    }
    &__main {
        position: relative;
    }
}

.product-params {
    margin-top: 2rem;
    &__title {
        font-style: normal;
        font-weight: 700;
        @include text14;
        margin-bottom: 1.25rem;
    }
    &__list {
        max-width: 15rem;
        @include r($sm) {
            max-width: 100%;
        }
    }
    &__item {
        font-weight: 500;
        @include text14;
        margin-bottom: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dotted #2C9AFF;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        span {
            background: #fff;
            margin-bottom: -1px;
            padding: 0 0.5rem;
            min-width: 15rem;
            @include r($sm) {
                min-width: auto;
            }
        }
    }
}

.product-info {
    max-width: 1250px;

    .nav {
        &-tabs {
            margin-bottom: 41px;
            flex-flow: row wrap;
            gap: 20px 144px;
            border: none;
            @include r($md) {
                margin-bottom: 26px;
                gap: 20px;
            }
        }

        &-link {
            margin: 0;
            padding: 0;
            color: $darkBlue;
            font-size: 18px;
            font-weight: 500;
            border: none;
            transition: .3s ease;
            @include r($md) {
                font-size: 12px;
            }

            &.active {
                color: $orange;
            }
        }
    }

    .tab {
        &-pane {
            .content {
                padding: 0;
            }
        }
    }
}

.product-specs {
    display: grid;
    gap: 42px 52px;
    grid-template-columns: 1fr 1fr;
    @include r($md) {
        grid-template-columns: 1fr;
        gap: 40px;
    }

    &__item {
        display: flex;
        align-items: center;
        gap: 28px;
        color: $darkBlue;
        font-size: 18px;
        line-height: 1.2;
        transition: .3s ease;
        @include r($md) {
            gap: 22px;
            font-size: 14px;
        }

        .item {
            &__icon {
                svg {
                    width: 48px;
                    height: 48px;
                    fill: $darkBlue;
                    transition: .3s ease;
                    @include r($md) {
                        width: 38px;
                        height: 38px;
                    }
                }
            }
        }

        &:hover {
            color: $orange;

            svg {
                fill: $orange;
            }
        }
    }
}
