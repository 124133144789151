:root {
    --bs-breadcrumb-divider: "|";
}

.breadcrumbs {
    padding: 0!important;
    margin: 30px 0 40px;
    @include r($md) {
        display: none;
    }

    & + * {
        padding-top: 0;
    }
}

.breadcrumb {
    font-size: 14px;

    a {
        color: $darkBlue;
        transition: .3s ease;
        &:hover {
            color: $orange;
        }
    }

    &-item {
        & + .breadcrumb-item {
            padding-left: 8px;
            &::before {
                padding-right: 8px;
            }
        }

        &.active {
            color: $deepBlue;
        }
    }
}
