.services-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    @include r($md) {
        grid-template-columns: repeat(1, 1fr);
    }

    &__item {
        padding: 30px 50px;
        display: flex;
        flex-flow: column;
        align-items: center;
        background: $sand;
        text-align: center;
        border-radius: 10px;
        @include r($md) {
            padding: 20px;
        }

        .item {
            &__icon {
                max-width: 124px;
                max-height: 124px;
                margin-bottom: 30px;

                img {
                    width: 100%;
                }

                @include r($md) {
                    max-width: 69px;
                    max-height: 69px;
                    margin-bottom: 20px;
                }
            }

            &__heading {
                margin-bottom: 20px;
                color: $darkBlue;
                font-size: 22px;
                font-weight: 700;
                line-height: 1.2;
                @include r($md) {
                    margin-bottom: 12px;
                    font-size: 18px;
                }
            }

            &__text {
                color: #3f3f3f;
                font-size: 18px;
                font-weight: 500;
                line-height: 1.4;
                @include r($md) {
                    font-size: 14px;
                }
            }
        }
    }
}
