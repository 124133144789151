.contacts {
    position: relative;
    padding: 0!important;
    @include r($md) {
        display: flex;
        flex-flow: column;
    }

    &__map {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 526px;
        overflow: hidden;
        z-index: 1;
        @include r($md) {
            min-height: 0!important;
            max-height: 360px;
            order: 1;
        }

        #map {
            width: 100%;
            height: 100%;
            min-height: 526px;
            @include r($md) {
                min-height: 0!important;
                max-height: 360px;
            }
        }
    }

    &-info {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 0;
        z-index: 2;
        @include r($md) {
            position: static;
            order: 0;
            height: auto;
            transform: unset;
        }

        &__content {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content: flex-end;
            @include r($md) {
                position: static;
            }
        }

        &__list {
            max-width: 580px;
            margin: 43px 0;
            padding: 29px 60px;
            display: flex;
            flex-flow: column;
            gap: 23px;
            background: $white;
            border-radius: 21px;
            @include r($md) {
                margin: 0 0 15px;
                padding: 0;
            }
        }

        &__item {

            &-wrap {
                padding: 22px 28px;
                display: flex;
                flex-flow: column;
                gap: 21px;
                background: $white;
                color: $darkBlue;
                border-radius: 21px;
                transition: .3s ease;
                @include r($md) {
                    padding: 18px 11px;
                    gap: 14px;
                }

                &:hover {
                    color: $darkBlue;
                }

                &.selected {
                    background: $sand;
                }
            }

            &-title {
                font-size: 24px;
                font-weight: 500;
                line-height: 1;
                @include r($md) {
                    font-size: 18px;
                }
            }

            span {
                font-size: 20px;
                line-height: 1.15;
                @include r($md) {
                    font-size: 16px;
                }

                a {
                    color: $orange;
                    transition: .3s ease;
                    &:hover {
                        color: $coral;
                    }
                }
            }
        }
    }

    &__form {
        position: relative;
        padding: 37px 0 70px;
        background: $deepBlue;
        overflow: hidden;
        @include r($md) {
            padding: 10px 0 32px;
        }

        &:last-child {
            margin-bottom: -4.7rem;
        }

        &-bg {
            position: absolute;
            top: 0;
            right: 20%;
            height: 100%;
            pointer-events: none;
            @include r($md) {
                display: none;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        *:not(input) {
            color: $white;
        }

        @include r($md) {
            .form-check-label {
                font-size: 10px;
                text-align: left;
            }
        }
    }
}

.social-item {
    display: block;
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;

    &:not(:last-child) {
        margin-right: 0.5rem;
    }
    svg {
        width: 100%;
        height: 100%;
        fill: $purple;
    }
}
