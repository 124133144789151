.client-list {
    .swiper {
        padding: 30px 20px;
        margin: -30px -20px;
    }

    &__item {
        padding: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        box-shadow: 0 11px 24px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        transition: .3s ease;
        @include r($md) {
            padding: 10px;
        }

        &:hover {
            box-shadow: 0 11px 24px rgba(223, 100, 64, 0.26);
        }

        img {
            width: 100%;
            max-height: 132px;
            object-fit: contain;
        }
    }

    .swiper-slide-prev,
    .swiper-slide-next + .swiper-slide + .swiper-slide {
        .client-list__item {
            box-shadow: none;
        }
    }
}
