.catalog__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px 20px;
    @include r($sm) {
        grid-template-columns: 1fr;
        gap: 40px 0;
    }

    &-column {
        display: flex;
        gap: 20px;

        .column {
            &__icon {
                width: 80px;
                height: 80px;
                @include r($sm) {
                    width: 69px;
                    height: 69px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            &__links {

            }

            &__heading {
                margin-bottom: 26px;
                display: inline-block;
                color: $orange;
                font-size: 20px;
                font-weight: 700;
                line-height: 1.15;
                @include r($sm) {
                    margin-bottom: 20px;
                }
            }

            &__list {
                padding-left: 1.2rem;
                list-style-type: disc;
            }

            &__item {
                font-size: 16px;
                line-height: 1.5;

                &:not(:last-child) {
                    margin-bottom: 1em;
                }

                &::marker {
                    color: $orange;
                }

                a {
                    color: $darkBlue;
                    transition: .3s ease;
                    &:hover {
                        color: $orange;
                    }
                }
            }
        }
    }
}
