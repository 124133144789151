.service {
    &__page {
        .container {
            display: flex;
            flex-flow: column;
        }

        &-image {
            border-radius: 10px;
            overflow: hidden;
            order: 0;
            @include r($md) {
                margin: 0 calc(var(--bs-gutter-x) * -0.5);
                border-radius: 0;
            }

            img {
                width: 100%;
                height: 276px;
                object-fit: cover;
            }
        }

        &-content {
            @include r($md) {
                order: 2;
            }
        }

        &-button {
            margin-top: 25px;
            @include r($md) {
                order: 1;
            }

            a {
                display: inline-block;
                padding: 11px 29px;
                background: $orange;
                color: $white;
                font-size: 16px;
                line-height: 1.95;
                border-radius: 41px;
                transition: .3s ease;
                @include r($md) {
                    max-width: 327px;
                    margin: 0 auto;
                    padding: 5px 27px;
                    display: block;
                    font-size: 10px;
                    line-height: 3.1;
                }

                &:hover {
                    background: $coral;
                }

                span {
                    font-weight: 700;
                }
            }
        }
    }
}
